import { useNavigate } from 'react-router-dom';
import {useEffect} from 'react';
import Account from '../Account';

function Logout() {
  const navigate = useNavigate();
  var account = Account;
  // Call navigate function to redirect to another page
  useEffect(() => {
    account.logout();
    navigate('/');
  }, []);

  return null;
}

export default Logout;
