/* This class is responsible for containing all readily accessible data from the users account
and asking the TomcatConnector to fetch account data and signin
*/
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;


var account = {
    username: null,
    id: 0,
    loggedIn: false,
    gender: null,
    name : null,
    DOB : 0,
    yearLevel : 0,
    school : null,
    occupation : null,
    qualification : null,
    accountType : false,
    language : null,
    admin: false,

  login: async function(usrnm, password) {
    //attempts to login with the username and password
    try {
      const response = await fetch(apiProd + '/account/login', {
        method: "POST",
        body: JSON.stringify({
          username: usrnm,
          password: password
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });

      //wait for the response from the api
      const data = await response.json();

      // Accessing the data
      console.log(data);
      //gets the number of results given from the database (if 0 then no account was found and login is denied)
      var numResults = data.results;

      //gets the account details from the data section
      data.data.forEach(user => {
        this.id = user.id;
        this.username = user.username;
        this.name = user.name;
        this.DOB = user.yearOfBirth;
        this.yearLevel = user.yearLevel;
        this.gender = user.gender;
        this.accountType = user.accountType;
        this.occupation = user.occupation;
        this.school = user.school;
        this.qualification = user.qualification;
        this.language = user.language;
        this.admin = user.admin;
      });

      if(numResults > 0){
        this.loggedIn = true;
        return true;
      }
      else{
        return false;
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  
  logout: function() {
    this.id = 0;
    this.loggedIn = false;
    this.username = null;
    this.name = null;
    this.DOB = 0;
    this.yearLevel = 0;
    this.gender = null;
    this.accountType = false;
    this.occupation = null;
    this.school = null;
    this.qualification = null;
    this.language = null;
    this.admin = false;
  }
};

  export default account;
