import React, { useState } from "react";
import Header from "../Header";
import Logo from '../../images/logo.png';
import Account from "../../Account";
import { Link, Navigate } from 'react-router-dom';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Update() {
    var account = Account;

    const [inputName, setInputName] = useState(account.name);
    const [inputGender, setInputGender] = useState(account.gender);
    const [inputDOB, setInputDOB] = useState(account.DOB);
    const [inputSchool, setInputSchool] = useState(account.school);
    const [inputYear, setInputYear] = useState(account.yearLevel);
    const [inputQualification, setInputQualification] = useState(account.qualification);
    const [inputOccupation, setInputOccupation] = useState(account.occupation);

    //if not logged in go to home page
    if(!account.loggedIn){
        return <Navigate to="/" replace />
    }

    async function updateAccount() {
        const response = await fetch(apiProd + `/account/${account.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gender: inputGender,
                name: inputName,
                yearOfBirth: inputDOB,
                yearLevel: inputYear,
                school: inputSchool,
                occupation: inputOccupation,
                qualification: inputQualification
            })
        });

        const data = await response.json();
        if(response.ok){
            setInputDOB(data.yearOfBirth);
            setInputGender(data.gender);
            setInputName(data.name);
            setInputOccupation(data.occupation);
            setInputQualification(data.qualification);
            setInputSchool(data.school);
            setInputYear(data.yearlevel);
        }
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    }

    const handleNameChange = (event) => {
        setInputName(event.target.value);
    }

    const handleGenderChange = (event) => {
        setInputGender(event.target.value);
    }

    const handleDOBChange = (event) => {
        setInputDOB(event.target.value);
    }

    const handleSchoolChange = (event) => {
        setInputSchool(event.target.value);
    }

    const handleYearChange = (event) => {
        setInputYear(event.target.value);
    }

    const handleQualificationChange = (event) => {
        setInputQualification(event.target.value);
    }

    const handleOccupationChange = (event) => {
        setInputOccupation(event.target.value);
    }

    return(
    <>
    <Header loggedIn={account.loggedIn}/>
    <div id="main" class="content login">
        <div class="logo">
            <img src={Logo} alt="Kids Questions Logo"/>
        </div>
        <h3>We see that this is your first time logging in to KidsQuestions,<br/> please tell us a little bit about yourself.</h3>
        <form class="content update">
        <input type="hidden" name="id" value={account.id}></input>

        <div className="field">
            
            <label for="username">Username</label>
            <input id="username" name="username" value={account.username} readOnly/>
            
            <div class= "field">
            <label for="name">Name</label>
            <input type="text" id="name" name="name" value={inputName} required onChange={handleNameChange}></input>
            </div>

            <div className="field">
            <label for="gender">Gender</label>
            <select id="gender" name="gender" value={inputGender} required onChange={handleGenderChange}>
                <option value="F">Female</option>
                <option value="M">Male</option>
                <option value="O">Other</option>
            </select>
            </div>
            
            <div className="field">
            <label for="year">Which year were you born?</label>
            <input type="number" id="year" name="year" value={inputDOB} min="1900" max="2025" required onChange={handleDOBChange}></input>
            </div>
        </div>

        <div className="field" value={account.accountType}>
            <label for="school">School</label>
            <input type="text" id="school" name="school" value={inputSchool} onChange={handleSchoolChange}></input>
            
            <div className="field">
            <label for="yearLevel">Year Level</label>
            <input type="text" id="yearLevel" name="yearLevel" value={inputYear} onChange={handleYearChange}></input>
            </div>
        </div>

        <div className="field" value={account.accountType}>
            <label for="qualification">Qualification</label>
            <input type="text" id="qualification" name="qualification" value={inputQualification} onChange={handleQualificationChange}></input>
            
            <div className="field">
            <label for="occupation">Occupation</label>
            <input type="text" id="occupation" name="occupation" value={inputOccupation} onChange={handleOccupationChange}></input>
            </div>
        </div>
        <input className="button" type="button" value="Update" onClick={async () => updateAccount()}></input>
        </form>
    </div>
    </>
    );
}

export default Update;

