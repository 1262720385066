import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import logo from '../images/logo.png';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import Account from '../Account';


function Login() {
    //get the account object
    var account = Account;
    //create variables for the password and username
    const [inputPassword, setInputPassword] = useState('');
    const [inputUsername, setInputUsername] = useState('');
    //useNavigate to reroute the page
    const navigate = useNavigate();


    function invalidSignIn(){
        alert('Please enter valid username and password');
    }

    //This updates the password variable according to changes made in the password input box
    const handlePasswordChange = (event) => {
        setInputPassword(event.target.value);
    };
    //This updates the username variable according to changes made in the username input box
    const handleUsernameChange = (event) => {
        setInputUsername(event.target.value);
    };

    /* This uses the account object to attempt to log the user in (which in turn uses the tomcatConnector object to query the database)
    if there is no response  */
    async function login(username, password){
        //this uses a fetch request to get the account details
        var response = await account.login(username, password);
        //if response is true then account was found
        if(response){
            //check the language the users account is set to and redirect to appropriate page
            if(account.language === "english"){
                //navigate to the english search page
                navigate('/English');
            }
            else{
                //navigate to the te reo maori search page
                navigate('/TeReo');
            }
        }
        else{ //else account was not found, ask for a valid username and password combination and deny access
            invalidSignIn();
        }
    }
    
  return (
    <div>
    <Header loggedIn={account.loggedIn}/>
    <div class="logo">
        <Link to="/"><img src={logo} alt="Kids Questions Logo"/></Link>
    </div>
    <div id="main" class="content login">
        Please use the username and password you have been provided
        <form class="content update">
            <div class="field">
                <label for="username">Username:</label>
                <input id="username" value={inputUsername} onChange={handleUsernameChange}></input>
                <label for="password" class="tag">Password:</label>
                <input id="password" type="password" value={inputPassword} onChange={handlePasswordChange}></input>
            </div>
            <div class="buttons">
                <input class="button-login" type="button" value="Log In" onClick={() => login(inputUsername, inputPassword)}></input>
                <Link to='/'><input className="button" type="button" value="About"></input></Link>
            </div>
        </form>
    </div>
    </div>
  );
}

export default Login
