import React from 'react'
import logo from '../images/logo.png'
import Header from './Header';
import Account from '../Account';

function About() {
    var account = Account;
  return (
    <>
    <Header loggedIn={account.loggedIn}/>
    <div class="content-about">
    <div class="logo">
        <img src={logo} alt="Kids Questions Logo"/>
    </div>
    <div class="body">
    <h1 class="about">KidsQuestions Project</h1>
    <p>
        KidsQuestions is a research based search engine interface prototype that has been developed to investigate how children's Internet searching might be assisted.
    </p>
    <h1 class="about">Research</h1>
    <p>
        We analysed the interfaces of current commercial internet search engines to highlight the design decisions and to assist with the development of requirements. 
        Activity theory was used as a lens to analyse the results of our studies in order to develop a list of requirements for an interaction model for children's information seeking. 
        These requirements were then used to design our interaction model. To examine this interaction model we developed an interface prototype, KidsQuestions. 
    </p>
    <h2>Publications resulting from the KidsQuestions Project</h2>
    <p>
        Vanderschantz, N. R. (2016). <i>KidsQuestions: Assisting children's digital information seeking</i>. (PhD Thesis). <a href='http://hdl.handle.net/10289/10811'>http://hdl.handle.net/10289/10811</a>
    </p>

    <p>
        Vanderschantz, N., Hinze, A., & Cunningham, S. J. (2014). Current educational technology use for digital information acquisition by young New Zealand children. 
        In B. Thomas, & D. Parry (Eds.), <i>Proc 37th Australasian Computer Science Conference</i> Vol. CRPIT 147 (pp. 125-134). Australia: ACS. 
        Retrieved from <a href='http://crpit.com/abstracts/CRPITV147Vanderschantz.html'>http://crpit.com/abstracts/CRPITV147Vanderschantz.html</a>
    </p>

    <p>
        Vanderschantz, N., Hinze, A. M., & Cunningham, S. J. (2014). Sometimes the Internet reads the question wrong: children's search strategies & difficulties.
         In <i>ASIST Proceedings of the ASIS&T 2014 Annual Meeting</i> Vol. 51 (pp. 10 pages). Seattle, WA: ASIST. 
         doi:<a href='http://onlinelibrary.wiley.com/doi/10.1002/meet.2014.14505101053/abstract'>10.1002/meet.2014.14505101053</a>
    </p>
    <h2>Further Work</h2>
    <p>  
        KidsQuestions is presently being developed to allow further studies with children & adults in future longitudinal, naturalistic, and lab based studies.
    </p>
    <h1 class="about">Design Approach</h1>
    <p>
        Our approach to designing a child-oriented interaction model differs from the common approach of using child-specific design devices such as colour, avatars, or results list simplification. 
        Instead, our interaction model explores the requirements for children's information seeking by aligning with appropriate phases of the inquiry-based learning frameworks children are taught. 
        By addressing the inquiry practices of children through interface elements that support query construction, query reconstruction, along with search planning tools, 
        KidsQuestions can assist children's digital information seeking.
    </p>
    <h1 class="about">Contact</h1>
    <p>
        Nicholas (Nic) Vanderschantz<br></br>
        Senior Lecturer Computer Graphic Design<br></br>
        University of Waikato<br></br>
        nic.vanderschantz[at]waikato.ac.nz
    </p>
    <h1 class="about">Links</h1>
    <p>
        KidsQuestions is project born out of the ISDB Group at the University of Waikato<br></br>
        <a href='http://isdb.cms.waikato.ac.nz/'>http://isdb.cms.waikato.ac.nz/</a>
    </p>
    <p>
        Research pertaining to children's and adults information seeking is also conducted by the Graphic Design Research Group at the Unviersity of Waikato.<br></br>
        <a href='http://www.cs.waikato.ac.nz/cgd/'>http://www.cs.waikato.ac.nz/cgd/</a>
    </p>
    </div>
    </div>
    </>
  );
}

export default About
