import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Account from '../../Account';
import Results from '../../components/ResultsTereo';
import SearchBlue from '../../images/search_blue.png';
import Menu from '../../components/Menu';
import logoSmall from '../../images/logo_small.png';
import { RANDOM } from 'mysql/lib/PoolSelector';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Result() {
    const account = Account;
    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState(location.state?.searchQuery || '');
    const [offset, setOffset] = useState(0);
    const [response, setResponse] = useState(location.state.response || []);
    const [display, setDisplay] = useState('none');
    const [noResults, setNoResults] = useState(response.length === 0);
    const [key, setKey] = useState(0);
    const [relatedSearches, setRelatedSearches] = useState(location.state.related);


    console.log("related" + relatedSearches);

    //checks if the user is logged in
    //denied unauthorised access to the site
    useEffect(() => {
        if (!account.loggedIn) {
            navigate('/');
        }
    }, [account, navigate]);

    //logs when the response is updated
    useEffect(() => {
        console.log('Response updated:', response);
        setNoResults(response.length === 0);
    }, [response]);


    // Calls findRelatedSearches when the component mounts
    useEffect(() => {
        findRelatedSearches(searchQuery); // Fetch related searches on initial load
    }, []);

    //when the search query in the text box is changed it changes it in the state
    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    //toggles the menu visibility
    const handleClick = () => {
        setDisplay(display === 'none' ? 'block' : 'none');
    };

    //gets search results from the api and logs the search
    const search = async (newOffset, newSearchQuery) => {
        try {
            var query;
            if(newSearchQuery != null){
                query = newSearchQuery;
            }
            else{
                query = searchQuery;
            }
            //only calls search if the search query is not empty
            if (query !== '' || query !== null) {
                const apiresponse = await fetch(apiProd + '/search', {
                    method: "POST",
                    body: JSON.stringify({
                        searchQuery: query,
                        account: account.id,
                        offset: newOffset
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });

                //gets the data and updates the state
                const data = await apiresponse.json();
                setResponse(data.items || []);
                var page = (newOffset / 10) + 1;
                //this key is passed with the results component so it will rerender and show the new search results
                //because before it was not rerendering even though items was being updated
                setKey(Math.random());
                console.log(data);

                //logs the search to kee track of peoples search history
                await fetch(apiProd + '/activity/search', {
                    method: "POST",
                    body: JSON.stringify({
                        account: account.id,
                        phrase: query,
                        page: page,
                        result: JSON.stringify(data),
                        language: account.language
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });

                await findRelatedSearches(query);
                console.log(await relatedSearches);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    
    //method to call for related searches from the api
    async function findRelatedSearches(searchQuery){
        try{
            
            var response = await fetch(apiProd + `/related/tereo/${searchQuery}`);
            var data = await response.json();
            console.log(data);
            if(response.status != 451){
                setRelatedSearches(await data);
                return;
            }
            else{
                return;
            }
        }
        catch(error){
            console.log(error);
            return null;
        }
    }



    //changes the question starter to the one selected
    function changeStartTo(word) {
        if (searchQuery !== "") {
            const phrases = ["Ko wai", "Kei hea", "Inahea", "He aha", "I pēhea", "Nā te aha"];
            
            for (let phrase of phrases) {
                if (searchQuery.toLowerCase().startsWith(phrase.toLowerCase())) {
                    // Set searchQuery to the new query array
                    setSearchQuery(searchQuery.replace(new RegExp(`^${phrase}`, 'i'), word));
                    return; // Exit the loop once a match is found and replaced
                }
            }
        } else {
            // Set searchQuery to selected word
            setSearchQuery(word);
        }
    }

    return (
        <>
            <div>
                <div className="header-bar">
                    <Link to='/'><img src={logoSmall} alt="Kids Questions Logo" className='header-logo' /></Link>
                    <div className='results-search'>
                        <input className='results-search-form' type="text" placeholder='Ask your questions' value={searchQuery} onChange={handleSearchQueryChange}></input>
                        <input type='image' className='button-search' src={SearchBlue} onClick={() => {setOffset(0); console.log(offset); search(0, null)}}></input>
                    </div>
                    <div className="account-info">
                        <div><button className="button-header" onClick={handleClick}>{account.username}</button></div>
                    </div>
                </div>
                <Menu display={display} />
                
                <div id="main" className="content result">
                    <div id="related-terms" class="related-terms">
                        <h2>He aha āu e hiahia ana ki te mōhio ki "{searchQuery}"?</h2>

                        <div class="question-words">
                        <span class="title emph">Ngā tīmatanga rerenga pātai:</span>
                            <span class="link" title="Click to set text field to 'Ko wai'" onClick={() => changeStartTo("Ko wai")}> Ko wai ...</span>
                            <span class="link" title="Click to set text field to 'Kei hea'" onClick={() => changeStartTo("Kei hea")}> Kei hea ...</span>
                            <span class="link" title="Click to set text field to 'Inahea'" onClick={() => changeStartTo("Inahea")}> Inahea ... i ... ai ...</span>
                            <span class="link" title="Click to set text field to 'He aha'" onClick={() => changeStartTo("He aha")}> He aha ...</span>
                            <span class="link" title="Click to set text field to 'I pēhea'" onClick={() => changeStartTo("I pēhea")}> I pēhea ...</span>
                            <span class="link" title="Click to set text field to 'Nā te aha'" onClick={() => changeStartTo("Nā te aha")}> Nā te aha ... i ... ai ...</span>
                        </div>
                        <div class="suggested-terms">
                            <div class="title emph">Ka taea e koe te pātai e pā ana ki:</div>
                            <ul>
                            {relatedSearches && relatedSearches.questions.map((item, index) => (
                                <li key={index} onClick={async  () => {await setSearchQuery(item.question); console.log(item.question); setOffset(0); console.log(offset); search(0, item.question)}} className="link">
                                    {item.question}
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    <div id="infobox" className="infobox"></div>
                    <div style={{ display: noResults ? 'none' : 'block' }}>
                        <Results items={response} page={offset/10 + 1} key={key} />
                        <div>
                        <p>
                        <span className="styled-anchor" onClick={() => {
                            if (offset >= 10) {
                                setOffset(prevOffset => {
                                    const newOffset = prevOffset - 10;
                                    search(newOffset);
                                    return newOffset;
                                });
                            }
                            }} style={{ display: offset >= 10 ? 'inline' : 'none' }}>Back
                        </span> 
                        <span className="styled-anchor"> {offset / 10 + 1} </span> 
                        <span className="styled-anchor" onClick={() => {
                            setOffset(prevOffset => {
                                const newOffset = prevOffset + 10;
                                search(newOffset, null);
                                return newOffset;
                            });
                            }}>Next
                        </span>
                        </p>                        
                        </div>
                    </div>
                    <div className="no-result" style={{ display: noResults ? 'inline' : 'none' }}>
                        <p>Your search did not match any documents.</p>
                        <p>Suggestions:</p>
                        <ul>
                            <li>Make sure all words are spelled correctly.</li>
                            <li>Try different keywords.</li>
                            <li>Try more general keywords.</li>
                            <li>Try fewer keywords.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Result;

