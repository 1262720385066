import React from 'react';
import Header from '../../Header';
import Account from '../../../Account';
import { useNavigate} from "react-router-dom";
import { useState, useEffect } from 'react';
import LinksTereo from '../../../components/LinksTereo';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function FlaggedLinks() {
  const navigate = useNavigate();
  const [rememberedTabs, setRememberedTabs] = useState();
  const [favouritedTabs, setFavouritedTabs] = useState();
  const [response, setResponse] = useState([]);
  var account = Account;

  useEffect(() => {
    if (!account.loggedIn) {
      navigate('/');
    }
  }, [account, navigate]);

    //call on first render
    useEffect(() => {
      async function fetchLinks() {
        try {
          //get the remembered links
          const apiresponse = await fetch(apiProd + `/user/links/${account.id}`);
          const data = await apiresponse.json();
          console.log(data); 


          if(Array.isArray(data)){
            console.log('this is an array');
          }
          console.log(data.length);
          // Check the structure of the data
          if (data.length > 0) {
            //set the response 
            setResponse(await data);
          } else {
            console.error('Unexpected data format:', data);
          }
        } catch (error) {
          console.error('Error fetching links:', error);
        }
      }
    
      fetchLinks();
    }, [account.id]);

  return (
    <div>
      <Header loggedIn={account.loggedIn}/>
      <div className="scrollable-container">
      {response.length > 0 ? (
        <LinksTereo items={response} />
      ) : (
        <div>You have no link activity to display.</div>
      )}
    </div>
    </div>
  );
}

export default FlaggedLinks;