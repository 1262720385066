import React, {useState, useEffect} from 'react';
import Account from '../Account';
import { useNavigate} from "react-router-dom";
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Searches(props) {
    const initialItems = props.items;
    
    const account = Account;
    const [items, setItems] = useState(initialItems);
    const navigate = useNavigate();
    const [relatedSearches, setRelatedSearches] = useState('');
    
    
    useEffect(() => {
        if (!account.loggedIn) {
            navigate('/');
        }
    }, [account, navigate]);

    async function search(phrase){
        try{
            if(phrase !== ''){
                var response = await fetch(apiProd + '/search', {
                    method: "POST",
                    body: JSON.stringify({
                      searchQuery: phrase,
                      account: account.id,
                      offset: 0
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8"
                    }
                });
                var data = await response.json();
                console.log(data);
            
            //log the search and the results
            var newresponse = await fetch(apiProd + '/activity/search', {
                method: "POST",
                body: JSON.stringify({
                    account : account.id,
                    phrase : phrase,
                    page : 1,
                    result : JSON.stringify(data),
                    language : account.language
                }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8"
                    }
            });
            console.log(newresponse.json);
            await findRelatedSearches(phrase);
            console.log(await relatedSearches);

            // Navigate with the parsed data
            if(account.language == 'english'){
                navigate('/Result-English', { state: { searchQuery: phrase, offset: 0, response: data.items, related: await relatedSearches} });
            }
            else{
                navigate('/Result-Tereo', { state: { searchQuery: phrase, offset: 0, response: data.items, related: await relatedSearches} });
            }
            }
            
            
        }
        catch(error){
            console.log(error);
        }
    }

    async function findRelatedSearches(phrase){
        try{
            var response = '';
            if(account.language == 'english'){
                response = await fetch(apiProd + `/related/${phrase}`);
            }
            else{
                response = await fetch(apiProd + `/related/tereo/${phrase}`);
            }

            var data = await response.json();
            console.log(data);
            if(response.status != 451){
                setRelatedSearches(await data);
                return;
            }
            else{
                return;
            }
        }
        catch(error){
            console.log(error);
            return null;
        }
    }

    const listItems = items.map(function(listItem, index) {
        if(listItem != null){
        return (
            <li>
            <div class="item">
                <div class="container">

                    <h3 className="link" onClick={async () => search(listItem.phrase)}>{listItem.phrase}</h3>
                    <div className="language">{listItem.language}</div>
                    <div className="created">{listItem.created}</div>
                </div>
            </div>
        </li>
        )}else{
            return null;
        }
    });
    return <ul id="links" class="items">{listItems}</ul>;
}

export default Searches;