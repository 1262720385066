import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';
import logo from '../../images/logo.png';
import Account from '../../Account';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Search() {
    var account = Account;
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [relatedSearches, setRelatedSearches] = useState();

    //do not allow users access if they are not logged in
    if(!account.loggedIn){
        navigate('/');
    }

    //This updates the searchQuery variable according to changes made in the search input box
    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    }

    //when the user clicks on the search button, it will redirect the user to the results page and pass the searchQuery as a prop
    //the results page will then call the search function in the account object and pass it the query
    async function search(){
        try{
            if(searchQuery !== ''){
                var response = await fetch(apiProd + '/search', {
                    method: "POST",
                    body: JSON.stringify({
                      searchQuery: searchQuery,
                      account: account.id,
                      offset: 0
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8"
                    }
                });
                var data = await response.json();
                console.log(data);
            
            //log the search and the results
            var newresponse = await fetch(apiProd + '/activity/search', {
                method: "POST",
                body: JSON.stringify({
                    account : account.id,
                    phrase : searchQuery,
                    page : 1,
                    result : JSON.stringify(data),
                    language : account.language
                }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8"
                    }
            });
            console.log(newresponse.json);
            await findRelatedSearches();
            console.log(await relatedSearches);

            // Navigate with the parsed data
            navigate('/Result-Tereo', { state: { searchQuery: searchQuery, offset: 0, response: data.items, related: await relatedSearches} });
            }
            
            
        }
        catch(error){
            console.log(error);
        }
    }

    async function findRelatedSearches(){
        try{
            
            var response = await fetch(apiProd + `/related/tereo/${searchQuery}`);
            var data = await response.json();
            console.log(data);
            if(response.status != 451){
                setRelatedSearches(await data);
                return;
            }
            else{
                return;
            }
        }
        catch(error){
            console.log(error);
            return null;
        }
    }

    //changes the question starter to the one selected
    function changeStartTo(word){
        if(searchQuery !== ""){
            const phrases = ["Ko wai", "Kei hea", "Inahea", "He aha", "I pēhea", "Nā te aha"];
        
            for (let phrase of phrases) {
                if (searchQuery.toLowerCase().startsWith(phrase.toLowerCase())) {
                    //setSearchQuery to the new query array
                    setSearchQuery(searchQuery.replace(new RegExp(`^${phrase.start}`, 'i'), word));
                }
            }
        }
        else{
            //set searchQuery to selected word
            setSearchQuery(word);
        }
    }

  return (
    <div>
      <Header loggedIn={account.loggedIn}/>
        <div id="main" class="content search">
            <div class="logo">
                <img src={logo} alt="Kids Questions Logo"/>
            </div>

            <div class="question-words">
        <span class="title emph">Ngā tīmatanga rerernga pātai:</span>
        <span class="link" title="Click to set text field to 'Ko Wai'" onClick={() => changeStartTo("Ko wai")}> Ko wai ...</span>
        <span class="link" title="Click to set text field to 'Kei hea'" onClick={() => changeStartTo("Kei hea")}> Kei hea ...</span>
        <span class="link" title="Click to set text field to 'Inahea'" onClick={() => changeStartTo("Inahea")}> Inahea ... i ... ai ...</span>
        <span class="link" title="Click to set text field to 'He aha'" onClick={() => changeStartTo("He aha")}> He aha ...</span>
        <span class="link" title="Click to set text field to 'I pēhea'" onClick={() => changeStartTo("I pēhea")}> I pēhea ...</span>
        <span class="link" title="Click to set text field to 'Nā te aha'" onClick={() => changeStartTo("Nā te aha")}> Nā te aha ... i ... ai ...</span>
    </div>

    <div class="clearfix"></div>
    <div id="searchForm" className="search-form">
        <div class="field">
            
        <div class="autocomplete">
                <input type="text" placeholder='Pātai i āu pātai' value={searchQuery} onChange={handleSearchQueryChange}></input>
        </div>

            {/* input type="text" id="searchTerm2" title="Search term" placeholder="Ask your question"*/}
        </div>
        <div class="buttons">
            <input class="button" type="button" value="Search" onClick={async () => search()}></input>
        </div>
    </div>

    <div class="recentQueries">
        <table>
            <tr>
                <td class="heading">Nga pātai i pātai tata nei:</td>
                <td class="queries">
                    <div class="link" title="Click to set the text field to '{{query}}'"></div>
                </td>
            </tr>
        </table>
    </div>
</div>

    </div>
  );
}

export default Search;

