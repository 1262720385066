import React, {useState, useEffect} from 'react';
import Account from '../../Account';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function LinkActivities() {
  const account = Account;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [noResults, setNoresults] = useState(false);

    if(!account.loggedIn){
      navigate('/');
    }

  useEffect(() => {
    fetch(apiProd + '/activity/link')
      .then((response) => response.json())
      .then((data) => setData(data));
      if(data === null){
        setNoresults(true);
      }
  }, [data]);
  return (
    <div>
      <Header loggedIn={account.loggedIn}/>
      <div id="main" class="content">
    <h1>Link Data Activity</h1>

    <p style={{display: noResults ? 'block' : 'none'}}>There is no activity data available.</p>

    <table>
        <tr>
            <th>ID</th>
            <th>Page</th>
            <th>Position</th>
            <th>Action</th>
            <th>New Status</th>
            <th>Link Data ID</th>
            <th>Account ID</th>
            <th>Date</th>
        </tr>
        {data.length > 0 && (
        data.map((activity) => (
          <tr>
            <td>{activity.id}</td>
            <td>{activity.page}</td>
            <td>{activity.position}</td>
            <td>{activity.action}</td>
            <td>{activity.newStatus}</td>
            <td>{activity.linkData}</td>
            <td>{activity.account}</td>
            <td>{activity.created}</td>
            <td>
                <ul class="actions">
                </ul>
            </td>
        </tr>
          ))
      )}
    </table>
</div>

    </div>
  );
}

export default LinkActivities
