import React, {useState, useEffect} from 'react';
import Account from '../Account';
import { useNavigate} from "react-router-dom";
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Links(props) {
    const initialItems = props.items;
    const style = ['orange', 'grey', 'blue', 'green', 'red'];
    const account = Account;
    const [items, setItems] = useState(initialItems);
    const navigate = useNavigate();
    
    
    useEffect(() => {
        if (!account.loggedIn) {
            navigate('/');
        }
        }, [account, navigate]);
    
    

    async function linkClick(linkdata, position, status){
        console.log(linkdata + " " + position + " " + status);
        if(status === null){
            status = 2;
        }
        else if( status === 0){
            status = 2;
        }
        else if(status === 1){
            status = 2;
        }
        else if(status === 2){
            status = 2;
        }
        else if(status === 3){
            status = 3;
        }
        else if(status === 4){
            status = 4;
        }
        //update the status of this item with the new status
        await updateStatus(linkdata, position, status);
    }  
    //when the user clicks on the marker for an item it will send an entry to linkdataactivity with an updated value for the marker's status
    async function updateStatus(linkdata, position, status){
        //create a new linkdataactivity entry for it with the updated status
        var response = await fetch(apiProd + '/activity/link', {
            method: "POST",
            body: JSON.stringify({
                linkdata: linkdata,
                account: account.id,
                page: 1,
                position: position,
                action: 2,
                newStatus: status
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        
        //update the items list so it gets displayed again
        const updatedItems = items.map((item, index) => {
            if (index === position) {
                return { ...item, status: status };
            }
            return item;
        });
        setItems(updatedItems);
    }

    async function markerClick(linkdata, position, status){
        //set the new status based on the old status
        console.log(linkdata + " " + position + " " + status);
        if(status === null){
            status = 0;
        }
        else if( status === 0){
            status = 1;
        }
        else if(status === 1){
            status = 0;
        }
        else if(status === 2){
            status = 3;
        }
        else if(status === 3){
            status = 4;
        }
        else if(status === 4){
            status = 2;
        }
        //update the status of this item with the new status
        await updateStatus(linkdata, position, status);
    }

    const listItems = items.map(function(listItem, index) {
        if(listItem != null){
        var listItemStyle = style[listItem.status] || 'grey';
        return (
            <li>
            <div class="item">
                <div class="container">
                    <div class="marker" title={listItem.status === 0 ? 'You wanted to visit this link later!' : listItem.status === 2 ? "You've already been here, click me to mark this link as helpful or unhelpful" : listItem.status === 3 ? 'You found this link helpful!' : listItem.status === 4 ? 'You found this link unhelpful!' : 'Click me to remember to visit this link later!'} style={{background: listItemStyle}} onClick={async () => markerClick(listItem.id, index, listItem.status)}>  </div>
                    <h3><a href={listItem.target} target='_blank' onClick={ /*if this item has been remembered or marked unhelpful dont change the value just record another visit*/async () => linkClick(listItem.id, index, listItem.status)}>{listItem.title}</a></h3>
                    <div class="target">{listItem.target}</div>
                    <div class="created">{listItem.created}</div>
                </div>
            </div>
        </li>
        )}else{
            return null;
        }
    });
    return <ul id="links" class="items">{listItems}</ul>;
}

export default Links;