import React from 'react'
import logoSmall from '../images/logo_small.png'
import { Link, useNavigate } from "react-router-dom";
import Account from '../Account';
import Menu from '../components/Menu';
import { useState } from 'react';
import SearchBlue from '../images/search_blue.png';


function Header(props) {
  var loggedIn = props.loggedIn;
  const navigate = useNavigate();
  const [ display, setDisplay ] = useState( 'none' )
  var account = Account;
  var buttonText;

  //checks if the user is logged in and displays correct text on header button
  if (loggedIn === true){ buttonText = account.username; }
  else buttonText = "Log In";

  //this handles the click of the header button
  function handleClick() {
    //if logged in this will toggle the display to a drop down menu
    if(loggedIn === true){
      //if the display is none, it will change the display to block
      if ( display === 'none' ) {
        setDisplay( 'block' )} 
      else { //otherwise it will change the display to none so it is invisible
        setDisplay( 'none' ) }}
    //if not logged in, it will take users to the log in page
    else{ navigate('/Login'); }
  }

  return (
    <>
    <div class="header-bar">
    {/* The Kids' Questions logo redirects to the about page */}
    <Link to='/'><img src={logoSmall} alt="Kids Questions Logo" class='header-logo'/></Link>
    <div class = "account-info"> 
    <div><button class="button-header" onClick={handleClick}>{buttonText}</button></div>
    </div>
    </div>
    <Menu display={display}/>
    </>
  );
}

export default Header
