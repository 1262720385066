import React, {useState, useEffect} from 'react';
import Account from '../../Account';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Links() {
  const account = Account;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [noResults, setNoresults] = useState(false);

    if(!account.loggedIn){
      navigate('/');
    }

  useEffect(() => {
    fetch(apiProd + "/link")
      .then((response) => response.json())
      .then((data) => setData(data));
      if(data === null){
        setNoresults(true);
      }
  }, [data]);
  return (
    <div>
      <Header loggedIn={account.loggedIn}/>
      <div id="main" class="content">
    <h1>Link Data</h1>

    <p style={{display: noResults ? 'block' : 'none'}}>There is no link data available.</p>

    <table>
        <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Target</th>
            <th>Acount ID</th>
            <th>Status</th>
        </tr>
        {data.length > 0 && (
        data.map((link) => (
          <tr>
            <td>{link.id}</td>
            <td>{link.title}</td>
            <td>{link.target}</td>
            <td>{link.account}</td>
            <td>{link.status}</td>
            <td>
                <ul class="actions">
                    <li>Delete</li>
                </ul>
            </td>
        </tr>
          ))
      )}
    </table>
</div>
    </div>
  );
}

export default Links;
