import React, {useState, useEffect} from 'react';
import Account from '../../Account';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Studies() {
  const account = Account;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [noResults, setNoresults] = useState(false);

    if(!account.loggedIn){
      navigate('/');
    }

  useEffect(() => {
    fetch(apiProd + "/study")
      .then((response) => response.json())
      .then((data) => setData(data));
      if(data === null){
        setNoresults(true);
      }
  }, [data]);

  return (
    <div>
      <Header loggedIn={account.loggedIn}/>
      <div id="main" class="content">
        <h1>Studies</h1>
        <form>
          <input type="hidden" name="id"/>
          <div class="field">
              <label for="studyname">Study Name</label>
              <input id="studyname" class="study" name="studyname" required/>
              <label for="startdate">Start Date</label>
              <input type="date" class="study" id="startdate" name="startdate"/>
              <label for="enddate">End Date</label>
              <input type="date" class="study" id="enddate" name="enddate"/> <br/>
              <input type="submit" class="button" value="Create"/>
          </div>
        </form>
        <p style={{display: noResults ? 'block' : 'none'}}>There are no studies available.</p>
        <table>
          <tr>
              <th>ID</th>
              <th>Study Name</th>
              <th>Start Date</th>
              <th>End Date</th>
          </tr>
          {data.length > 0 && (
            data.map((study) => (
            <tr>
              <td>{study.id}</td>
              <td>{study.studyName}</td>
              <td>{study.startDate}</td>
              <td>{study.endDate}</td>
              <td>
                  <ul class="actions">
                      <li>Delete</li>
                  </ul>
              </td>
            </tr>
          )))}
        </table>
      </div>
    </div>
  );
}

export default Studies
