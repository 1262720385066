import { BrowserRouter, Routes, Route } from "react-router-dom";
import './global.css';
import About from './pages/About';
import Login from "./pages/Login";
import EnglishSearch from "./pages/English/Search";
import MaoriSearch from './pages/Maori/Search';
import EnglishResult from "./pages/English/Result";
import MaoriResult from './pages/Maori/Result';
import Logout from './pages/Logout';
import UpdateEnglish from "./pages/English/Update";
import UpdateTeReo from "./pages/Maori/Update";
import Account from "./Account";
import FlaggedLinksEnglish from './pages/English/User/FlaggedLinks';
import SearchHistoryEnglish from './pages/English/User/SearchHistory';
import FlaggedLinksTeReo from './pages/Maori/User/FlaggedLinks';
import SearchHistoryTeReo from './pages/Maori/User/SearchHistory';
import Accounts from './pages/Admin/Accounts';
import LinkActivities from './pages/Admin/LinkActivities';
import Links from './pages/Admin/Links';
import SearchActivities from './pages/Admin/SearchActivities';
import Studies from './pages/Admin/Studies';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<About />}/>
      <Route path='Login' element={<Login />}/>
      <Route path='Logout' element={<Logout />}/>
      <Route path='English' element={<EnglishSearch />}/>
      <Route path='TeReo' element={<MaoriSearch />}/>
      <Route path='Result-English' element={<EnglishResult />}/>
      <Route path='Result-TeReo' element={<MaoriResult />}/>
      <Route path='Update-English' element={<UpdateEnglish />}/>
      <Route path='Update-TeReo' element={<UpdateTeReo />}/>
      <Route path='Flagged-Links-English' element={<FlaggedLinksEnglish />}/>
      <Route path='Flagged-Links-TeReo' element={<FlaggedLinksTeReo />}/>
      <Route path='Search-History-English' element={<SearchHistoryEnglish />}/>
      <Route path='Search-History-TeReo' element={<SearchHistoryTeReo />}/>
      <Route path='Accounts' element={<Accounts />}/>
      <Route path='Link-Activities' element={<LinkActivities />}/>
      <Route path='Links' element={<Links />}/>
      <Route path='Search-Activities' element={<SearchActivities />}/>
      <Route path='Studies' element={<Studies />}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
