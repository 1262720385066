import React, {useState, useEffect} from 'react';
import Account from '../../Account';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function Accounts() {
  const account = Account;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [noResults, setNoresults] = useState(false);
  const [updatingAccount, setUpdatingAccount] = useState("");
  const [creatingAccount, setCreatingAccount] = useState("");
  const [updatingID, setUpdatingID] = useState("0");
  const [test, setTest] = useState("");

  if(!account.loggedIn){
    navigate('/');
  }

  GetAccounts();

  //this will send a request for all user accounts and then when the response is received, will update the page
  function GetAccounts(){
    useEffect(() => {
      fetch(apiProd + '/account')
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          console.log(data);
          if (data == null) {
            setNoresults(true);
          }
        }).catch((error) => {
          console.error("Error fetching data:", error);
          // Handle any error scenarios here
        });
    }, []);
  }

  //this should call the account that is being updated 
  useEffect(() => {
    if(updatingID === "0"){
      //donothing
    }
    else{
    fetch(apiProd + '/account/' + updatingID)
      .then((response) => response.json())
      .then((updatingAccount) => {
        setUpdatingAccount(updatingAccount);
      });
}}, [updatingID]);

  //this will update an account according to the details entered into the updateAccount form
  async function updateAccount(updatingID){
    //send a request to update all the details of the account
    fetch(apiProd + 'account/' + updatingID, {
  method: "put",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },

  //make sure to serialize your JSON body
  body: JSON.stringify({admin: false, language: "english", id:104, username:"sdsdg", password:"jttjyf", name:"Dana Greaves", gender:null, yearOfBirth:1990, yearLevel:0, school:null, occupation:null, qualification:"BCompSci"})
})
.then( (response) => response.json())
.then((updatingAccount) => setUpdatingAccount(updatingAccount));
   //call the useeffect function by resetting data
   GetAccounts();
}

  //this will toggle the display of the updateAccount form
  //it will also fetch the user details and display them on the form if it is being toggled to true
  function ToggleUpdateAccount(id){
    setShowUpdate(!showUpdate);
    setUpdatingID(id);
    //send a request to fetch user details if show update is true
  }

  //this will delete the account selected
  function deleteAccount(id){
    //send a request to the tomcat to delete the account
  }

  //send a fetch request that creates a new account
  function createAccount(){

  }

  return (
    <div>
      <Header loggedIn={account.loggedIn}/>
      <div id="main" class="content">
        <h1>{test}</h1>
        <input id="createAccount" type="button" class="button" value={showCreate ? 'Cancel' : 'Create Account'} onClick={() => setShowCreate(!showCreate)}/>
    
        <form style={{display: showCreate ? 'block' : 'none'}}>
          <input type="hidden" name="id" value=""/>
            <div class="field">
              <label for="username">Username</label>
              <input id="username" name="username" required/>
            
            <div class="field">
              <label for="password">Password</label>
              <input id="password" name="password" required/>
            </div>

            <div class="field">
              <label for="accountType">Kids Account?</label>
              <input type="checkbox" id="accountType" name="accountType"/>
            </div>
            
            <div class="field">
              <label for="name">Name</label>
              <input id="name" name="name"/>
            </div>
            
            <div class="field">
              <label for="gender">Gender</label>
              <select id="gender" name="gender">
                <option value="F">Female</option>
                <option value="M">Male</option>
                <option value="O">Other</option>
              </select>
            </div>

            <div class="field">
              <label for="year">Birthday</label>
              <input type="number" id="year" name="year" min="1900" max="2017" value="1990"/>
            </div>
          </div>

        <div class="field">
            <label for="school">School</label>
            <input id="school" name="school"/>
            
            <div class="field">
            <label for="yearLevel">Year Level</label>
            <input id="yearLevel" name="yearLevel"/>
            </div>
        </div>

        <div class="field">
            <label for="qualification">Qualification</label>
            <input id="qualification" name="qualification"/>
            
            <div class="field">
            <label for="occupation">Occupation</label>
            <input id="occupation" name="occupation" value="job"/>
            </div>
        </div>

        <div class="buttons">
            <input readOnly="true" type="button" class="button" value="Create"/>
        </div>
    </form>

    <form style={{display: showUpdate ? 'block' : 'none'}}>
        <label>Account ID: {updatingAccount.id}</label>

        <div class="field">
            <label for="username">Username</label>
            <input id="upUsername" name="username" value={updatingAccount.username}/>
            
            <label for="password">Password</label>
            <input id="upPassword" name="password" value={updatingAccount.password}/>
            
            <label for="accountType">Kids Account?</label>
            <input type="checkbox" id="upAccountType" name="accountType"/>
            
            <label for="name">Name</label>
            <input id="upName" name="name" value={updatingAccount.name}/>
            
            <label for="gender">Gender</label>
            <select id="upGender" name="gender">
                <option value="F">Female</option>
                <option value="M">Male</option>
                <option value="O">Other</option>
            </select>
            
            <label for="year">Birthday</label>
            <input type="number" id="upYear" name="year" min="1900" max="2017" value={updatingAccount.yearOfBirth}/>
        </div>

        <div class="field">
            <label for="school">School</label>
            <input id="upSchool" name="school" value={updatingAccount.school}/>
            
            <label for="yearLevel">Year Level</label>
            <input id="upYearLevel" name="yearLevel"/>
        </div>

        <div class="field">
            <label for="qualification">Qualificaiton</label>
            <input id="upQualification" name="qualification" value={updatingAccount.qualification}/>
            
            <label for="occupation">Occupation</label>
            <input id="upOccupation" name="occupation" value={updatingAccount.occupation}/>
        </div>


        <div class="buttons">
            <input readOnly="true" class="button" value="Update" onClick={async () => updateAccount(updatingID)}/>
            <input readOnly="true" class="button" value="Cancel" onClick={() => ToggleUpdateAccount("0")}/>
        </div>
    </form>



    <p style={{display: noResults ? 'block' : 'none'}}>There are no user accounts.</p>

    <table>
        <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Password</th>
            <th>Name</th>
            <th>Actions</th>
        </tr>
        {data.length > 0 && (
        data.map((user) => (
          <tr>
            <td key={user.id}>{user.id}</td>
            <td key={user.id}>{user.username}</td>
            <td key={user.id}>{user.password}</td>
            <td key={user.id}>{user.name}</td>
            <td>
              <ul className="actions">
                <li onClick={() => ToggleUpdateAccount(user.id)}>Update</li>
                <li onClick={() => deleteAccount(user.id)}>Delete</li>
              </ul>
            </td>
          </tr>
          ))
      )}
       </table>
      </div>

    </div>
  );
}

export default Accounts;