import React, {useState, useEffect} from 'react';
import Account from '../Account';
import { useNavigate} from "react-router-dom";
const apiProd = process.env.REACT_APP_API_URL_PROD;
const apiDev = process.env.REACT_APP_API_URL_DEV;

function ResultsTereo(props) {
    const initialItems = props.items;
    const page = props.page;
    const style = ['orange', 'grey', 'blue', 'green', 'red'];
    const account = Account;
    const [items, setItems] = useState(initialItems);
    const [key, setKey] = useState(props.key);
    const navigate = useNavigate();


    useEffect(() => {
        if (!account.loggedIn) {
          navigate('/');
        }
      }, [account, navigate]);

    //this key ensures that the component rerenders when the search button is hit
    useEffect(() => {
        console.log('Key updated:', key);
    }, [key]);
    
    //when the user clicks on the marker for an item it will send an entry to linkdataactivity with an updated value for the marker's status
    async function updateStatus(linkdata, position, title, target, action, status){
        //if null then create new linkdata entry for it
        //else create a new linkdataactivity entry for it with the updated status
        var response;
        if(linkdata == null){
            //create new linkdata entry
            response = await fetch(apiProd + '/link/', {
                method: "POST",
                body: JSON.stringify({
                    title: title,
                    target: target,
                    status: status,
                    account: account.id
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            let responseData = await response.json();
            linkdata = responseData.insertId;
            console.log("insert id: " + responseData.insertId);
        }
        
        response = await fetch(apiProd + '/activity/link', {
            method: "POST",
            body: JSON.stringify({
                linkdata: linkdata,
                account: account.id,
                page: page,
                position: position,
                action: action,
                newStatus: status
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        

        const updatedItems = items.map((item, index) => {
            if (index === position) {
                return { ...item, linkdataStatus: status };
            }
            return item;
        });
        setItems(updatedItems);

        console.log(response);
    }

    async function markerClick(linkdata, position, title, target, status){
        //set the new status based on the old status
        console.log(status);
        if(status == null){
            status = 0;
        }
        else if( status === 0){
            status = 1;
        }
        else if(status === 1){
            status = 0;
        }
        else if(status === 2){
            status = 3;
        }
        else if(status === 3){
            status = 4;
        }
        else if(status === 4){
            status = 2;
        }
        console.log(status);

        await updateStatus(linkdata, position, title, target, 0, status);
    }

    const listItems = items.map(function(listItem, index) {
        if(listItem != null){
        var listItemStyle = style[listItem.linkdataStatus] || 'grey';
        return (
            <li>
            <div class="item">
                <div class="container">
                    <div class="marker" title={listItem.linkdataStatus === 0 ? 'You wanted to visit this link later!' : listItem.linkdataStatus === 2 ? "You've already been here, click me to mark this link as helpful or unhelpful" : listItem.linkdataStatus === 3 ? 'You found this link helpful!' : listItem.linkdataStatus === 4 ? 'You found this link unhelpful!' : 'Click me to remember to visit this link later!'} style={{background: listItemStyle}} onClick={async () => markerClick(listItem.linkdataID, index, listItem.title, listItem.link, listItem.linkdataStatus)}>  
                    </div>
                    <h3><a href={listItem.formattedUrl} target='_blank' onClick={ /*if this item has been remembered or marked unhelpful dont change the value just record another visit*/async () => updateStatus(listItem.linkdataID, index, listItem.title, listItem.link, 1, (listItem.linkdataStatus === 3 || listItem.linkdataStatus === 4) ? listItem.linkdataStatus : 2)}>{listItem.title}</a></h3>
                    <div class="target">{listItem.displayLink}</div>
                    <div class="snippet">{listItem.snippet}</div>
                </div>
            </div>
        </li>
        )}else{
            return null;
        }
    });
    return <ul id="results" class="items">{listItems}</ul>;
}

export default ResultsTereo;