import React from 'react';
import Account from '../Account';
import { useNavigate } from 'react-router-dom';

const Menu = (props) => {
  const account = Account;
  const navigate = useNavigate();

  if(account.loggedIn){
    if(account.admin
      ){
    return (
      <div class="menu" style={{display:props.display}}>
        <input type='button' class='menuItem' value="Accounts" onClick={() => navigate('/Accounts')}/>
        <input type='button' class='menuItem' value="Link Activities" onClick={() => navigate('/Link-Activities')}/>
        <input type='button' class='menuItem' value="Links" onClick={() => navigate('/Links')}/>
        <input type='button' class='menuItem' value="Search Activities" onClick={() => navigate('/Search-Activities')}/>
        <input type='button' class='menuItem' value="Studies" onClick={() => navigate('/Studies')}/>
        <input type='button' class='menuItem' value="Log Out" onClick={() => navigate('/Logout')}/>
      </div>
    );
    }
  
    else if(account.language === 'maori'){
      return (
        <div class="menu" style={{display:props.display}}>
          <input type='button' class='menuItem' value="Search" onClick={() => navigate('/English')}/>
          <input type='button' class='menuItem' value="About" onClick={() => navigate('/')}/>
          <input type='button' class='menuItem' value="Remembered Links" onClick={() => navigate('/Flagged-Links-English')}/>
          <input type='button' class='menuItem' value="Search History" onClick={() => navigate('/Search-History-English')}/>
          <input type='button' class='menuItem' value="User Details" onClick={() => navigate('/Update-English')}/>
          <input type='button' class='menuItem' value="Log Out" onClick={() => navigate('/Logout')}/>
        </div>
      );
    }
    else{
      return (
        <div class="menu" style={{display:props.display}}>
          <input type='button' class='menuItem' value="Search" onClick={() => navigate('/TeReo')}/>
          <input type='button' class='menuItem' value="About" onClick={() => navigate('/')}/>
          <input type='button' class='menuItem' value="Remembered Links" onClick={() => navigate('/Flagged-Links-Tereo')}/>
          <input type='button' class='menuItem' value="Search History" onClick={() => navigate('/Search-History-Tereo')}/>
          <input type='button' class='menuItem' value="User Details" onClick={() => navigate('/Update-Tereo')}/>
          <input type='button' class='menuItem' value="Log Out" onClick={() => navigate('/Logout')}/>
        </div>
      );
    }
  }
}

export default Menu
